@import '~/lib/styles/vars.scss';

.nav-main {
  .logo {
    height: 6.4rem;
    width: 80%;
    margin-right: 2rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 10px auto;
  }
}
