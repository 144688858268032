.fixed-footer {
  width: calc(100% + 48px);
  height: 60px;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  top: calc(100vh - 60px);
  margin-left: -24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 40px;

  .footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    .ant-btn {
      padding: 0 40px;
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
