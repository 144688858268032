.wrapper {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 60px;
  @media (max-width: 1300px) {
    padding: 0 3rem;
  }
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
}
