.user-avatar {
  background-color: #bfbfbf;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  width: 45px;
  height: 45px;
  font-size: 21px;
  margin-left: 15px;

  &.anticon {
    color: #fff;
  }
  &.img {
    padding: 0;
  }
}
