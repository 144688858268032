.date-range-slider {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > :first-child {
    flex: 1;
  }
  > div > div {
    // margin: 0 !important;
    // width: 100% !important;
  }
  .thumb {
    height: 24px;
    width: 24px;
    outline: none;
    border-radius: 21px;
    background-color: white;
    border: solid rgb(222, 222, 222) 1px;
    box-shadow: 0 0 3px 0;
    position: relative;
    text-align: center;
  }
}
