.page.nessus {
  .floating-section {
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    width: 100%;

    .severity-cards {
      display: flex;

      .severity-card {
        flex: 1;
        padding: 5px 10px;
        margin-right: 20px;
        border-radius: 6px;
        cursor: pointer;

        .severity {
          display: block;
          font-size: 17.3394px;
        }
        .threat-count {
          display: block;
          font-size: 30px;
        }

        &.critical {
          color: rgba(255, 6, 6, 0.9);

          &.selected {
            background-color: rgba(255, 6, 6, 0.9);
          }
        }
        &.high {
          color: #ff8b1f;

          &.selected {
            background-color: #ff8b1f;
          }
        }
        &.medium {
          color: #ffdb1f;

          &.selected {
            background-color: #ffdb1f;
          }
        }
        &.low {
          color: #7dd353;

          &.selected {
            background-color: #7dd353;
          }
        }
        &.info {
          color: #595959;

          &.selected {
            background-color: #595959;
          }
        }
        &.selected {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: white;
          border-bottom: none;
        }
      }
    }

    &:nth-child(1) {
      padding: 20px 0;
    }
    &.authorized-vulnerabilities {
      width: 100%;

      h3 {
        margin-bottom: 40px;
      }
    }
  }
}
