.floating-section.tickets {
  .counts {
    min-width: 50%;
    display: flex;
    justify-content: space-between;

    > div span {
      margin-right: 20px;
    }
  }
}
