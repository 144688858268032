.phishing-campaigns {
  .flex {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
    .legend {
      display: flex;

      .key {
        display: flex;
        align-items: center;
        margin-right: 10px;
        .bubble {
          margin-right: 5px;
        }
      }
    }
  }
  .bubbles {
    display: flex;
  }
  .bubble {
    width: 10px;
    height: 10px;
    border-radius: 100%;

    &.attachment-opened {
      background-color: #fbb709;
    }
    &.opened {
      background-color: purple;
    }
    &.replied {
      background-color: yellow;
    }
    &.data-entered {
      background-color: #fb0909;
    }
    &.reported {
      background-color: blue;
    }
  }
  .ant-table-wrapper {
    margin-top: 20px;
  }
}
