// CSS vars
:root {
  // Main Colors
  --blue: rgb(10, 73, 117);
  --blueLight: rgb(45, 151, 239);
  --orange: rgba(237, 94, 43, 1);
  --error: var(--orange);
  --orangeLight: #ff8c59;
  --aqua: rgb(152, 195, 212);
  --darkBlue: #1374c3;
  --lightBlue: #1890fe;
  --lime: #7cb305;
  --purple: #b37feb;
  --red: #cf1322;
  --gold: #d48806;

  // Defaults
  --gutter: 50px;
  --borderRadius: 0.5rem;
}

// Breakpoint vars
$gt-cinema: 'only screen and (min-width: 1800px)';
$lt-desktop: 'only screen and (max-width: 1300px)';
$lt-phone: 'only screen and (max-width: 768px)';
