.payment-methods {
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    display: inline-block;
    // margin: 5px;
    margin-left: auto;
    @media (max-width: 1000px) {
      margin-top: 40px;
    }
  }
  td {
    .active-icon {
      display: block;
      margin: auto;
      font-size: 16px;
    }
  }
  .add-method-btns {
    min-width: fit-content;
    width: 47%;
    margin-left: auto;
    @media (max-width: 976px) {
      display: flex;
      width: 100%;
    }
  }
}
