.billing-status-container {
  p {
    font-weight: 500;
    margin: 0;
  }
  .inactive-status-text {
    display: inline-block;
    color: #ff4d4f;
  }
  .ant-alert {
    width: 200px;
    min-width: fit-content;
    min-height: fit-content;
  }
  .active-billing-alert {
    .active-status-text {
      color: #52c41a;
    }
  }

  .billing-btn {
    width: 150px;
    margin: 5px;
  }
}
