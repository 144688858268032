.page.privacy {
  width: 100%;

  .section-title {
    font-weight: bold;
    font-size: 14px;
    color: black;
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
  th,
  td {
    padding: 5px;
  }
  > div {
    margin: 0 auto;
    width: fit-content;
    text-align: center;
    table {
      text-align: left;
      margin-top: 10px;
    }
  }
}
