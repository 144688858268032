.floating-section.dns-filter {
  width: 100%;
  position: relative;

  .flex-options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .threat-select {
      display: flex;
      align-items: center;

      h3 {
        margin-bottom: 0;
        margin-right: 10px;
      }
      span {
        display: flex;
        align-items: center;
        svg {
          margin-left: 5px;
        }
      }
    }
    .ant-select {
      flex: 1;
      margin-right: 20px;
    }
    &:first-of-type {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
  .request-threat {
    display: block;
  }
  .table-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .allow-block {
      margin-top: 10px;
      display: inline-block;
      .block:not([disabled]) {
        color: #f0656f;
      }
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .download-button {
    display: block;
    margin-left: auto;
  }
  .request-graphs {
    display: flex;

    > div {
      width: 50%;
    }
    .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend .antd-pro-charts-pie-chart {
      width: 100%;
    }
    .antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
      position: relative;
      transform: none;
    }
    // .request-graph:first-of-type {
    //   .antd-pro-charts-pie-pie .antd-pro-charts-pie-value {
    //     width: 100%;
    //     text-align: right;
    //     height: 100%;

    //     .val-hover-box {
    //       width: 100%;
    //       position: absolute;
    //     }
    //   }
    // }
  }
}

.threat-options {
  .threat-option {
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }
  }
}
