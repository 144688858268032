@import '~/lib/styles/vars.scss';

.top-bar {
  background-color: white;
  height: 64px;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  .user-name {
    margin-left: auto;
  }
}
