.page.dashboard {
  display: flex;
  // height: max-content;
  overflow-y: scroll;
  // width: 100%;
  .float-wrapper {
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 6rem);

    .floating-section {
      display: inline-block;
      float: left;
      margin-bottom: 20px;
      width: 100%;
      flex: 1;

      &:nth-child(2) {
        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }
      &:nth-child(3) {
        .ant-select {
          width: 100%;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &:nth-child(1) {
      width: calc(100% / 3 * 2);
      margin-right: 20px;
    }
    &:nth-child(2) {
      width: calc(100% / 3);
    }
  }
}

.threat-options {
  .threat-option {
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }
  }
}
