.floating-section.password-security {
  .expiring-in {
    color: rgba(0, 0, 0, 0.45);
  }
  .counts {
    .count {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        margin-right: 20px;
        min-width: 30%;
        color: rgba(0, 0, 0, 0.45);
      }
      .percent-bar {
        background-color: #eff2f5;
        flex: 1;
        max-width: 80%;
        height: 100%;
        min-height: 15px;
        position: relative;

        .percent {
          height: 100%;
          min-height: 15px;
        }
      }
    }
  }
}
