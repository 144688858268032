.page.app-river {
  .floating-section {
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    &:nth-child(1) {
      width: calc(100% / 3 * 2 - 10px);
      margin-right: 20px;
    }
    &:nth-child(2) {
      width: calc(100% / 3 - 10px);
    }
    &.released-emails {
      width: 100%;
    }

    &.released-emails h3 {
      margin-bottom: 40px;
    }
  }
}
