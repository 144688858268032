@import '~/lib/styles/vars.scss';
@import '~/lib/styles/fonts.scss';

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 62.5%;
  letter-spacing: 0.05em;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  margin: 0;
  color: #262626;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
h1 {
  color: #595959;
  font-size: 20.6677px;
  line-height: 28px;
}
h3 {
  color: #1890ff;
}

.bold {
  font-weight: bold;
}

#app {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;

  .floating-section {
    background-color: white;
    padding: 10px 20px;
  }
  .page-wrap {
    flex: 1;
    width: 80%;
    max-width: 100%;
    margin: 0 auto;
    min-height: 90vh;
    background-color: #f0f2f5;
    overflow-y: scroll;

    .page {
      margin: 0 auto;
      min-height: 90vh;
      padding: 3rem;
      background-color: #f0f2f5;
      overflow-y: scroll;

      // @media #{$lt-desktop} {
      //   padding: 3rem;
      // }
      // @media #{$lt-phone} {
      //   padding: 1.5rem;
      // }
    }
  }
}

.page {
  // width: 80%;
  // max-width: 100%;
  margin: 0 auto;
  min-height: 90vh;
  padding: 3rem;
  background-color: #f0f2f5;
  overflow-y: scroll;

  // @media #{$lt-desktop} {
  //   padding: 3rem;
  // }
  // @media #{$lt-phone} {
  //   padding: 1.5rem;
  // }
}

.flex {
  display: flex;
  align-items: center;
  width: 40%;

  input {
    margin-right: 20px;
  }
}

.ant-btn.action-button {
  display: block;
  margin-left: auto;
}

.ant-input {
  //   min-height: 43px;
}

.scroll-modal .ant-modal-content {
  padding-bottom: 5px;

  .ant-modal-body {
    max-height: 70vh;
    overflow-y: scroll;
  }
}

.ant-descriptions-item-label {
  font-weight: bold !important;
}

.ant-btn.column-button {
  display: block;
  width: 95px;
  margin-bottom: 5px;
}

.ant-upload.ant-upload-drag .ant-upload.ant-upload-btn {
  padding: 10px 15px;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container p.ant-upload-drag-icon {
  margin-bottom: 0;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.panel-data-box {
  background-color: #fff;
  padding: 0 5px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  position: relative;
}

.drawer-edit-row {
  width: 100%;
}
