.severity-cards {
  .severity-card {
    padding: 5px 10px;
    margin-right: 20px;
    border-radius: 6px;
    cursor: pointer;
    border-bottom: 1px solid #e8e8e8;

    .severity {
      display: block;
      font-size: 14x;
    }
    .threat-count {
      display: block;
      font-size: 20px;
    }

    &.critical {
      color: rgba(255, 6, 6, 0.9);

      &.selected {
        background-color: rgba(255, 6, 6, 0.9);
      }
    }
    &.high {
      color: #ff8b1f;

      &.selected {
        background-color: #ff8b1f;
      }
    }
    &.medium {
      color: #ffdb1f;

      &.selected {
        background-color: #ffdb1f;
      }
    }
    &.low {
      color: #7dd353;

      &.selected {
        background-color: #7dd353;
      }
    }
    &.info {
      color: #595959;

      &.selected {
        background-color: #595959;
      }
    }
    &.selected {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: white;
    }
  }
}
