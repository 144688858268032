.page.know-before {
  .floating-section {
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    &:nth-child(1) {
      width: calc(100% / 3 - 10px);
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;

      .g-guide {
        top: 65% !important;
        left: 0 !important;
      }
      .g-guide,
      .g-guide div,
      canvas {
        width: 100% !important;
      }
    }
    &:nth-child(2) {
      width: calc(100% / 3 * 2 - 10px);
    }
    &.training-campaigns,
    &.phishing-campaigns {
      width: 100%;
    }

    &.training-campaigns h3 {
      margin-bottom: 40px;
    }
  }
}
