.page.tickets {
  .flex {
    align-items: stretch;
    width: 100%;

    .floating-section {
      background-color: white;
      padding: 10px 20px;
      width: calc(45% - 10px);
      margin-bottom: 20px;
      &:first-child {
        margin-right: 10px;
        width: calc(55% - 10px);
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        color: rgba(108, 108, 108, 0.85);

        .antd-pro-charts-pie-pie {
          > div,
          > div > div,
          > div > div > div,
          > div > div > div > div,
          > div > div > div > div > div,
          > div > div > div > div > div > div,
          canvas {
            bottom: 0;
            height: 100% !important;
          }
        }
        > div {
          flex: 1;
        }
        > div,
        h3,
        p {
          width: 100%;
        }
        p:last-of-type {
          border-top: 2px solid #e8e8e8;
          padding-top: 10px;
          margin-top: 20px;
        }
      }
    }
  }
  .floating-section {
    background-color: white;
    padding: 10px 20px;
  }
  .ticket-table {
    .new {
      color: var(--lime);
    }
    .in-progress {
      color: var(--purple);
    }
    .urgent {
      color: var(--red);
    }
    .high {
      color: var(--gold);
    }
  }
}
