.page.password-security {
  .floating-section {
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    h3 {
      margin-bottom: 40px;
    }

    &:nth-child(1) {
      display: flex;
      flex-wrap: wrap;

      h3 {
        width: 100%;
      }
      > div {
        width: 20%;

        .pie-stat {
          font-size: 15px;
        }
      }
    }
  }
}
