.ticket-view {
  position: relative;

  .styled-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > span {
      display: block;
      width: 40%;
      padding-right: 10px;
      font-size: 15.2031px;
      line-height: 24px;
    }
    .ant-input,
    .ant-select {
      flex: 1;
    }
    .ant-select-arrow {
      display: none;
    }
    &.subject span {
      width: auto;
    }
  }
  .ticket-form {
    padding-bottom: 20px;
  }
  .ant-drawer-body {
    background-color: #fafafa;
    padding-bottom: 0;
  }
  .expand-section-container.ant-collapse-borderless {
    background-color: #fafafa;
    margin-bottom: 60px;

    .ant-collapse-item {
      border-bottom: 0;
    }
  }
  .company-info {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;

    .account-number {
      color: #096dd9;
      cursor: pointer;

      span {
        background-color: #1890ff;
        padding: 1px;

        svg {
          color: #fff;
        }
      }
    }
  }
  .ticket-info-container {
    .ticket-info {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .status {
        display: flex;
        align-items: baseline;
        margin-right: 20px;

        .status-circle {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          display: block;
          margin-right: 5px;

          svg {
            margin-right: 10px;
          }
          &.new {
            background-color: #f5222d;
          }
          &.in-progress {
            background-color: #1890ff;
          }
          &.complete {
            background-color: #389e0d;
          }
        }
      }
      .duration {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
          font-size: 20px;
        }
      }
    }
  }
  .ticket-details {
    .ant-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .ant-form-item-label {
        width: 40%;
        text-align: left;
      }
      .ant-form-item-control-wrapper {
        width: 60%;
      }
    }
  }
}
