.ticket-summary-container {
  background-color: #fff;
  padding: 0 5px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  position: relative;

  .user-avatar {
    width: 35px;
    height: 35px;
    font-size: 15px;
    margin-right: 20px;
  }

  .old-comment {
    display: flex;
    margin-bottom: 20px;

    .comment-data {
      width: 60%;

      .name {
        color: #8c8c8c;
        font-size: 12px;
        line-height: 20px;
        padding-right: 5px;
      }
      .created-date {
        color: #8c8c8c;
        font-size: 10px;
        line-height: 16px;
      }
      .comment {
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        margin-top: 5px;
      }
    }
  }
  .new-comment {
    display: flex;
    padding-right: 20px;

    .ant-form-item {
      width: 100%;
    }
  }
  .action-button {
    margin-right: 20px;
  }
}
