.ticket-user {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.assign-popover {
  .ant-popover-inner-content {
    padding: 0;
  }

  .assign-option {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 16px;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      color: #fff;
    }
  }
}
