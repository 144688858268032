.page.sign-in {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 0px;
  h2,
  p {
    margin-top: 20px;
    text-align: center;
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    min-height: fit-content;
  }
  .login-box {
    width: 45%;
    height: 80%;
    .login-box-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 375px;
      height: 525px;
      margin-left: auto;
      margin-top: 50px;
      @media only screen and (max-width: 720px) {
        margin-left: initial;
        margin: auto;
        margin-top: 50px;
      }
      img {
        width: 243px;
        height: 108px;
      }
      .welcome-text {
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 25px;
        line-height: 41px;
        letter-spacing: 0.6px;
        span {
          color: #26abe2;
          font-weight: bold;
          font-size: 28px;
          line-height: 53px;
          letter-spacing: 0.18px;
        }
      }

      .login-form {
        width: 100%;
        margin-bottom: 50px;
        .ant-form-item {
          margin-bottom: 0px;
          height: 80px;
          position: relative;
          .ant-form-explain {
            position: relative;
            top: -40px;
          }
        }
        .login-field {
          height: 55px;
          padding: 20px 0px 0px 20px;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          background-color: transparent;
          // position: relative;
          // top: -38px;
        }
        .ant-form-item-label {
          position: absolute;
          left: 20px;
        }
        .ant-form-item-control {
          // position: relative;
          // left: 20px;
          width: 100%;
        }
        .ant-form-item-no-colon {
          font-size: 12px;
          color: grey;
        }
      }
      .button-group {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        div > a,
        button.ant-btn-primary {
          font-size: 14px;
          width: 150px;
          height: 43px;
          font-weight: bold;
          letter-spacing: 0.11px;
        }
        &:nth-child(odd) {
          font-size: 20px;
          line-height: 25px;
        }
        &:nth-child(even) {
          font-size: 28px;
          line-height: 35px;
        }
      }
    }
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
  .gradients-box {
    width: 55%;
    height: 100%;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    .light-blue-poly {
      display: inline-block;
      clip-path: polygon(0% 100%, 20% 100%, 100% 0%, 90% 0%);
      width: 35%;
      height: 100vh;
      background-color: red;
      background: linear-gradient(
        180.03deg,
        #146a91 0.02%,
        rgba(91, 186, 229, 0.17) 99.98%
      );
      opacity: 0.2;
      @media only screen and (max-width: 720px) {
        display: none;
      }
    }
    .dark-blue-poly {
      display: inline-block;
      position: absolute;
      left: 48.8%;
      clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 30.2% 0%);
      width: 51.24%;
      height: 100vh;
      background: linear-gradient(160.86deg, #3f93b9 8.24%, #13709b 97.55%);
      img {
        position: absolute;
        width: 445px;
        right: 0;
        bottom: 0;
      }
      .ant-carousel {
        position: relative;
        top: 22%;
        left: 32%;
        width: 416px;
        @media only screen and (max-width: 720px) {
          position: relative;
          top: 22%;
          left: 20%;
          width: 65%;
        }
      }
      .carousel {
        height: 199px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 50px;
        letter-spacing: 0.6px;
        color: #f2f2f2;
      }
      @media only screen and (max-width: 720px) {
        position: initial;
        clip-path: initial;
        width: 100%;
        img {
          left: initial;
          top: initial;
          width: 100%;
        }
      }
    }
    .carousel-item {
      p {
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 24px;
        line-height: 50px;
        letter-spacing: 0.6px;
      }
      .author,
      .author-details {
        text-align: right;
      }
      .author-details {
        font-weight: 200;
        font-size: 20px;
      }
    }
  }
}
